import React from "react";
import axios from "axios";

class SmallLoginForm extends React.Component {
  constructor(props) {
    super(props);
    const { csrfCookieName, csrfHeaderName,logUrl } = this.props;
    axios.defaults.xsrfCookieName = csrfCookieName;
    axios.defaults.xsrfHeaderName = csrfHeaderName;
    axios.defaults.withCredentials = true;

    this.state = {
      user: {
        email: "",
        password: "",
        errors: {
          pristine: "pristine",
        },
      },
      loginResult: {
        hasError: false,
        errorMessage: "",
      },
      loading: false,
      userIsAuthenticated: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.getUserIsAuthenticated = this.getUserIsAuthenticated.bind(this);
  }

  validateEmail(value) {
    const error = {};
    // if (!value) {
    //   error['email'] = 'El correo electrónico es obligatorio.';
    // }
    // else {
    let emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!value.match(emailFormat)) {
      error["email"] = "El correo electrónico no es válido.";
    } else {
      if (value.length < 7) {
        error["email"] = "El correo electrónico es muy corto.";
      } else {
        if (value.length > 200) {
          error["email"] = "El correo electrónico es muy largo.";
        }
      }
    }
    // }
    return error;
  }

  validatePassword(value) {
    const error = {};
    if (!value) {
      error["password"] = "La contraseña es obligatoria.";
    } else {
      if (value.length < 3) {
        error["password"] = "La contraseña es muy corta.";
      } else {
        if (value.length > 100) {
          error["password"] = "La contraseña es muy larga.";
        }
      }
    }
    return error;
  }

  onChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const { user } = this.state;

    user[name] = value;

    if ("pristine" in user.errors) {
      delete user.errors["pristine"];
    }

    if (name === "email") {
      const emailError = this.validateEmail(value);
      if (Object.keys(emailError).length === 0) {
        if ("email" in user.errors) {
          delete user.errors["email"];
        }
      } else {
        user.errors = Object.assign(user.errors, emailError);
      }
    }

    if (name === "password") {
      const passwordError = this.validatePassword(value);
      if (Object.keys(passwordError).length === 0) {
        if ("password" in user.errors) {
          delete user.errors["password"];
        }
      } else {
        user.errors = Object.assign(user.errors, passwordError);
      }
    }

    this.setState({
      user: user,
    });
  }

  formIsValid() {
    const { user } = this.state;
    // const emailError = this.validateEmail(user.email);
    const passwordError = this.validatePassword(user.password);
    return (
      // Object.keys(emailError).length === 0 &&
      Object.keys(passwordError).length === 0
    );
  }
  salir(logUrl) {
    const request = {
      method: "get",
      url: "/logout/", //harcodeado la ruta
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios(request)
      .then((response) => {
        window.location.replace(logUrl);
      })
      .catch((error) => {});
  }
  getUserIsAuthenticated() {
    const self = this;
    const { apiBaseUrl } = this.props;
    axios
      .get("/api/v1/users/authenticated/")
      .then(function (response) {
        self.setState((prevState) => ({
          userIsAuthenticated: response.data.data.userIsAuthenticated,
        }));
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  componentDidMount() {
    this.getUserIsAuthenticated();
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const self = this;
    const { apiBaseUrl, nextUrl,logUrl } = this.props; // ruta original
    const { user, loginResult } = this.state;

    loginResult.hasError = false;
    loginResult.errorMessage = "";

    this.setState({
      loading: true,
      loginResult: loginResult,
    });

    const request = {
      method: "post",
      url: "/api/v1/users/login/", //harcodeado la ruta
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: user,
    };
    axios(request)
      .then((response) => {
        window.location.replace(logUrl); //harcodeado la ruta
        // window.location.reload();
      })
      .catch((error) => {
        loginResult.hasError = true;
        loginResult.errorMessage = error.response.data.error;
        self.setState({
          loading: false,
          loginResult: loginResult,
        });
      });
  }

  render() {
    const { loading, user, loginResult } = this.state;
    const { authenticationModes, setAuthenticationMode,logUrl } = this.props;
    const { userIsAuthenticated } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <div className=" p-md-5 pt-md-0">
            <div className="">
              <div className="login-title">
                <h6 className="verde bold textFont">
                  <b>
                  INFORMATE

                  </b>
                  </h6>
                <h6 className="bold textFont">
                  <b>
                  QUIERO PARTICIPAR

                  </b>
                  </h6>
                {userIsAuthenticated && (
                  <a className="link-primary text-decoration-none text-dark textFont">
                    <button onClick={() => this.salir(logUrl)} className="logout-btn textFont">
                      Salir{" "}
                    </button>
                  </a>
                )}
                {!userIsAuthenticated && <h6>
                  <b>
                  Iniciar sesión

                  </b>
                  </h6>}
              </div>
              <div className="hr"></div>
              {!userIsAuthenticated && (
                <form
                  className="row g-3 "
                  onSubmit={this.onSubmit}
                  noValidate
                  disabled={loading}
                >
                  <div className="mb-3 text-center ">
                    <label class="verde bold textFont">
                      <b>
                        Cedula de Identidad
                        </b>
                        </label>
                    <input
                      type="email"
                      name="email"
                      className={"form-control  " + "email"}
                      onChange={this.onChange}
                      value={user.email}
                      minLength={7}
                      maxLength={200}
                      placeholder=""
                      required
                      autoFocus
                    />
                  </div>
                  <div className="mb-3 text-center">
                    <label class="verde bold textFont">
                      <b>
                      Contraseña
                      </b>
                      </label>
                    <input
                      type="password"
                      name="password"
                      className={
                        "form-control " +
                        ("password" in user.errors && "is-invalid")
                      }
                      onChange={this.onChange}
                      value={user.password}
                      minLength={3}
                      maxLength={100}
                      placeholder=""
                      required
                    />
                  </div>
                  <div className="mb-1 text-center ">
                    <div className="d-grid gap-2">
                      <button
                        type="submit"
                        className="btn btn-dark textFont"
                        disabled={!this.formIsValid()}
                      >
                        <strong>
                          INICIAR SESIÓN{" "}
                          {loading && (
                            <i className="fa fa-spinner fa-pulse fa-fw" />
                          )}
                        </strong>
                      </button>
                    </div>

                    {loginResult.hasError && (
                      <div className="alert alert-danger mt-3 textFont" role="alert">
                        {/* {loginResult.errorMessage} */}
                        Cedula de identidad o contraseña incorrectas
                      </div>
                    )}
                  </div>
              <div className="hr"></div>
                  {/* <div className="clearfix mt-3 pb-1">
                    <hr />
                  </div> */}
                  <p className="text-center mt-0">
                    <a
                      href="/registrarse/"
                      className="login-signup-link text-decoration-none text-black textFont"
                      onClick={(e) => {
                        setAuthenticationMode(e, authenticationModes.SIGNUP);
                      }}
                    >
                      <b>
                      REGISTRARSE

                      </b>
                    </a>
                  </p>
                  {/* <p className="text-center mt-0">
                  <a href="#" className="login-signup-link"
                    onClick={(e) => { setAuthenticationMode(e, authenticationModes.PASSWORD) }}>
                    ¿Te olvidaste la contraseña?
                  </a>
                </p> */}
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SmallLoginForm;
